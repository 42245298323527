import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'
import FormHelperText from '@mui/material/FormHelperText'

import { DatePickerController } from '../../components/forms/fields/DatePickerController'
import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { RemainingProductDto } from '../../generated/api/breadlove'
import { Path } from '../../router/BaseRouter/enums'
import { getRemainingProductsAfterDeadline } from '../../service/products.service'
import { sessionOrderActions, sessionOrderState } from '../../store/slices/sessionOrderSlice'
import { useSchema } from './schema'

export type Step1FormValues = InferType<ReturnType<typeof useSchema>>

export const Step1 = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const schema = useSchema()
	const dispatch = useDispatch()
	const order = useSelector(sessionOrderState)
	const [soldOut, setSoldOut] = useState<boolean>(false)

	const { handleSubmit, control, reset, watch } = useForm<Step1FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			pickupDate: undefined
		}
	})

	const pickupDate = watch('pickupDate')

	useEffect(() => {
		reset({
			pickupDate: order.pickupDate ? new Date(order.pickupDate) : undefined
		})
	}, [])

	useEffect(() => {
		dispatch(sessionOrderActions.setStep3({ products: [] }))
		if (pickupDate) {
			void loadRemainingProductsAfterDeadline(pickupDate)
		}
	}, [pickupDate])

	const loadRemainingProductsAfterDeadline = async (date: Date) => {
		try {
			const res = await getRemainingProductsAfterDeadline(date)
			setSoldOut(res.data.some((product: RemainingProductDto) => product.remaining === 0 && product.deadlineLimit > 0))
		} catch {
			toast.error(t('general.notification.unexpectedError'))
		}
	}

	return (
		<ScreenWrapper
			step={1}
			routeBack={Path.HOME}
			title={'step1.title'}
			subTitle={'step1.subTitle'}
			onSubmit={handleSubmit((data) => {
				try {
					dispatch(sessionOrderActions.setStep1(data))
					navigate(Path.STEP2)
				} catch (e) {}
			})}
		>
			<Box style={{ width: '100%' }}>
				<Grid container direction='column' mb={2}>
					<Grid item>
						<Typography variant='subtitle2'>{t('step1.form.pickupDate')}</Typography>
					</Grid>
					<Grid item>
						<DatePickerController name='pickupDate' control={control} />
						{soldOut && (
							<FormHelperText sx={{ margin: 0 }} error>
								{t('general.validation.thisDaySoldOut')}
							</FormHelperText>
						)}
					</Grid>
				</Grid>
			</Box>
		</ScreenWrapper>
	)
}
