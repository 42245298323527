import React, { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { InferType } from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { FormHelperText, Grid, Typography } from '@mui/material'
import Box from '@mui/material/Box'

import { NumInputController } from '../../components/forms/fields/NumInputController'
import { toast } from '../../components/LsToast'
import { ScreenWrapper } from '../../components/ScreenWrapper'
import { TableSkeleton } from '../../components/TableSkeleton'
import { ProductDto, RemainingProductDto } from '../../generated/api/breadlove'
import { Path } from '../../router/BaseRouter/enums'
import { getProducts, getRemainingProductsAfterDeadline } from '../../service/products.service'
import { sessionOrderActions, sessionOrderState } from '../../store/slices/sessionOrderSlice'
import { useSchema } from './schema'

export type Step3FormValues = InferType<ReturnType<typeof useSchema>>
const HARD_REMAINING_LIMIT = 10
export const Step3 = () => {
	const { t } = useTranslation()
	const navigate = useNavigate()
	const schema = useSchema()
	const dispatch = useDispatch()
	const order = useSelector(sessionOrderState)
	const [productsLoading, setProductsLoading] = useState<boolean>(true)
	const [products, setProducts] = useState<ProductDto[]>([])
	const [remainingProductsAfterDeadline, setRemainingProductsAfterDeadline] = useState<RemainingProductDto[]>([])
	const {
		handleSubmit,
		control,
		reset,
		getValues,
		setValue,
		formState: { errors }
	} = useForm<Step3FormValues>({
		resolver: yupResolver(schema),
		defaultValues: {
			products: []
		}
	})

	useEffect(() => {
		const productsForm = products.map((value) => ({ productId: value.id, count: 0 }))
		if (order.products && order.products?.length > 0 && productsForm && productsForm.length > 0) {
			order.products.forEach((order) => {
				productsForm.find((value) => {
					return value.productId === order.productId
				})!.count = order.count
			})
		}
		reset({
			products: productsForm ? productsForm : []
		})
	}, [products])

	useEffect(() => {
		if (!order.pickupDate || !order.pickupTime) {
			navigate(Path.HOME)
		}
		void loadProducts()
	}, [order])

	const loadProducts = async () => {
		setProductsLoading(true)
		try {
			const products = await getProducts(new Date(order.pickupDate!))
			setProducts(products.data)
			const remainingProducts = await getRemainingProductsAfterDeadline(new Date(order.pickupDate!))
			setRemainingProductsAfterDeadline(remainingProducts.data)
		} catch (e) {
			toast.error(t('general.notification.unexpectedError'))
		} finally {
			setProductsLoading(false)
		}
	}

	return (
		<ScreenWrapper
			step={3}
			routeBack={Path.STEP2}
			title={'step3.title'}
			subTitle={'step3.subTitle'}
			onSubmit={handleSubmit((data) => {
				try {
					dispatch(sessionOrderActions.setStep3(data))
					navigate(Path.STEP4)
				} catch (e) {}
			})}
		>
			<Grid container direction='column'>
				<Grid item>
					{!productsLoading && (
						<Grid container direction='column'>
							{products.map((product, index) => {
								const remainingProduct = remainingProductsAfterDeadline.find((value) => value.id === product.id)
								const limit = remainingProduct ? remainingProduct.remaining : product.limit
								return (
									<Grid
										key={product.id}
										item
										mb={2}
										display='flex'
										justifyContent='space-between'
										alignItems='center'
										sx={{ border: '1px solid #1E1E1E', borderRadius: '4px', padding: (theme) => theme.spacing(3, 2) }}
									>
										<Box>
											<Typography variant='h4'>{product.name}</Typography>
											<Box>
												<Typography variant='h5' component='span'>
													{product.price}
												</Typography>
												<Typography
													variant='caption'
													component='span'
													sx={{
														marginLeft: '2px',
														marginRight: (theme) => theme.spacing(1),
														alignSelf: 'flex-start',
														position: 'relative',
														top: '-3px'
													}}
												>
													00
												</Typography>
												<Typography variant='h5' component='span'>
													Kč
												</Typography>
												{remainingProduct &&
													(remainingProduct.remaining !== remainingProduct.deadlineLimit ||
														remainingProduct.remaining < HARD_REMAINING_LIMIT) &&
													remainingProduct.remaining > 0 && (
														<FormHelperText
															sx={{
																fontWeight: 'normal',
																textTransform: 'none',
																fontFamily: `"DM Sans", "Helvetica", "Arial", sans-serif`
															}}
															error
														>
															{t('general.validation.remaining')} {remainingProduct.remaining}
														</FormHelperText>
													)}
											</Box>
										</Box>
										<Box>
											{remainingProduct && remainingProduct.remaining <= 0 && remainingProduct.deadlineLimit > 0 ? (
												<FormHelperText sx={{ fontSize: '16px' }} error>
													{t('general.validation.soldOut')}
												</FormHelperText>
											) : (
												<NumInputController
													getValue={getValues}
													setValue={setValue}
													name={`products.${index}.count`}
													control={control}
													max={limit}
												/>
											)}
										</Box>
									</Grid>
								)
							})}
						</Grid>
					)}
					{productsLoading && <TableSkeleton spacing={3} height={88} size={10} />}
				</Grid>
				{errors?.products?.root && (
					<Grid item>
						<FormHelperText sx={{ margin: 0 }} error>
							{errors.products.root.message}
						</FormHelperText>
					</Grid>
				)}
			</Grid>
		</ScreenWrapper>
	)
}
