import React, { useEffect, useState } from 'react'
import { Control, Controller, FieldPath, FieldValues } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import InputAdornment from '@mui/material/InputAdornment'
import { TextFieldVariants } from '@mui/material/TextField'
import { MobileDatePicker } from '@mui/x-date-pickers'

import { getOpeningHours } from '../../../../service/orders.service'
import { toast } from '../../../LsToast'

type Props<Values extends FieldValues> = {
	name: FieldPath<Values>
	control: Control<Values>
	variant?: TextFieldVariants
	disabled?: boolean
}

export const DatePickerController = <Values extends FieldValues>({
	name,
	control,
	variant = 'filled',
	disabled
}: Props<Values>) => {
	const { t } = useTranslation()
	const [disabledDates, setDisabledDates] = useState<Date[]>([])
	const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
	const [loading, setLoading] = useState(true)

	const fetchDisabledDates = async (startDate: Date, endDate: Date) => {
		try {
			setLoading(true)
			const datesToCheck = []
			const date = new Date(startDate)
			while (date <= endDate) {
				datesToCheck.push(new Date(date))
				date.setDate(date.getDate() + 1)
			}

			const results = await Promise.all(
				datesToCheck.map(async (date) => {
					const { data } = await getOpeningHours(date)
					return data.open ? null : date
				})
			)

			setDisabledDates((prev) => [...prev, ...results.filter((date): date is Date => date !== null)])
			setLoading(false)
		} catch (e) {
			toast.error(t('general.notification.unexpectedError'))
		}
	}

	useEffect(() => {
		const today = new Date()
		const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0, 12, 0, 0)
		void fetchDisabledDates(today, endOfMonth)
	}, [])

	const handleMonthChange = (date: Date) => {
		const newMonth = date.getMonth()
		if (newMonth !== currentMonth) {
			setCurrentMonth(newMonth)
			const startOfMonth = new Date(date.getFullYear(), newMonth, 1, 12, 0, 0)
			const endOfMonth = new Date(date.getFullYear(), newMonth + 1, 0, 12, 0, 0)
			void fetchDisabledDates(startOfMonth, endOfMonth)
		}
	}

	const shouldDisableDate = (date: Date) => {
		return loading || disabledDates.some((disabledDate) => disabledDate.toDateString() === date.toDateString())
	}

	return (
		<Controller
			name={name}
			control={control}
			disabled={disabled}
			render={({ field: { onChange, value, ref }, fieldState }) => (
				<MobileDatePicker
					disablePast
					format='EEEE - dd.MM.yyyy'
					shouldDisableDate={shouldDisableDate}
					value={value}
					onMonthChange={handleMonthChange}
					onChange={(date) => {
						if (date) {
							const adjustedDate = new Date(date)
							adjustedDate.setHours(12, 0, 0, 0)
							onChange(adjustedDate)
						} else {
							onChange(date)
						}
					}}
					slotProps={{
						day: {
							sx: {
								'&.Mui-disabled:not(.Mui-selected)': {
									color: '#F0E8DC'
								}
							}
						},
						textField: {
							InputProps: {
								placeholder: t('general.button.choose'),
								endAdornment: (
									<InputAdornment position='end'>
										<CalendarMonthIcon />
									</InputAdornment>
								)
							},
							inputRef: ref,
							variant: variant,
							fullWidth: true,
							disabled: disabled,
							sx: {
								'& .MuiInputBase-input': {
									padding: '17px 16px',
									textTransform: 'capitalize',
									'&.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
										color: 'red'
									}
								}
							},
							error: !!fieldState.error?.message,
							helperText: fieldState.error?.message
						}
					}}
					sx={{
						width: '100%'
					}}
				/>
			)}
		/>
	)
}
