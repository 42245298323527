import { ProductsApi } from '../generated/api/breadlove'
import axios from './axios'

const productsApi = new ProductsApi(undefined, process.env.REACT_APP_BE_URL, axios)

export const getProducts = (date: Date) => {
	return productsApi.getAllProducts(date.toISOString(), true)
}

export const getRemainingProductsAfterDeadline = (date: Date) => {
	return productsApi.getRemainingProductsAfterDeadline(date.toISOString())
}
